import { clsx } from 'clsx';

import type { SingleDefinitionListProps } from './type';

export const SingleDefinitionList = ({
  title,
  children,
}: SingleDefinitionListProps) => {
  return (
    <dl className="tw-flex tw-items-center tw-bg-blue-100 tw-border tw-border-blue-100 tw-rounded tw-overflow-hidden">
      <dt className="tw-px-4 tw-bg-blue-100 tw-text-sm tw-text-blue-700 tw-whitespace-nowrap">
        {title}
      </dt>
      <dd
        className={clsx(
          'tw-flex-1 tw-px-4 tw-py-2.5 tw-bg-blue-50 tw-text-sm tw-break-all'
        )}
      >
        {children}
      </dd>
    </dl>
  );
};
