import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import clsx from 'clsx';

import type { BaseModalProps } from './type';

export const BaseModal = ({
  title,
  children,
  allowBodyOverflow = false,
  footer,
  withCloseButton,
  isFullWidthBody,
  contentMinHeight = 'unset',
  bodyClassName,
  ...modalProps
}: BaseModalProps) => {
  const setPadding = (type: 'content' | 'header' | 'body' | 'footer') => {
    if (isFullWidthBody) {
      switch (type) {
        case 'content':
        case 'body':
          return 0;
        case 'header':
        case 'footer':
          return 6;
      }
    }
    switch (type) {
      case 'content':
        return 6;
      case 'header':
      case 'body':
      case 'footer':
        return 0;
    }
  };
  return (
    <Modal isCentered={true} scrollBehavior="inside" {...modalProps}>
      <ModalOverlay zIndex={1600} />
      <ModalContent
        minH={contentMinHeight}
        py="4"
        px={setPadding('content')}
        containerProps={{
          zIndex: '1600',
        }}
      >
        <ModalHeader py="0" px={setPadding('header')} fontSize="lg">
          {title}
        </ModalHeader>
        {withCloseButton && <ModalCloseButton />}

        {children && (
          <ModalBody
            mt="5"
            py="0"
            px={setPadding('body')}
            className={clsx(bodyClassName)}
            overflow={allowBodyOverflow ? 'inherit' : 'auto'}
          >
            {children}
          </ModalBody>
        )}

        <ModalFooter mt="4" py={0} px={setPadding('footer')}>
          {footer}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
