import { CheckboxGroup } from '@chakra-ui/react';
import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { InputCheckboxGroupProps } from './type';

import { InputCheckbox } from '@/components/ui';
import { parseToNumber } from '@/utils/string';

export const InputCheckboxGroup = forwardRef<
  HTMLInputElement,
  InputCheckboxGroupProps
>(
  (
    {
      options,
      direction = 'row',
      itemWidth = 'auto',
      isInvalid = false,
      isNumericValue = false,
      isNumStringMixedValue = false,
      onChange,
      ...inputProps
    },
    ref
  ) => {
    const handleChange: InputCheckboxGroupProps['onChange'] = (inputs) => {
      if (isNumericValue) {
        const parsedInputValue = inputs
          .map((v) => parseToNumber(v))
          .flatMap((v) => (v === undefined ? [] : [v]));
        onChange && onChange(parsedInputValue);
      } else if (isNumStringMixedValue) {
        const parsedInputValue = inputs
          .map((v) => {
            const res = Number(v);
            if (Number.isNaN(res)) {
              return v;
            }
            return parseToNumber(v);
          })
          .flatMap((v) => (v === undefined ? [] : [v]));

        onChange && onChange(parsedInputValue);
      } else {
        onChange && onChange(inputs);
      }
    };

    return (
      <CheckboxGroup onChange={handleChange} {...inputProps}>
        <div
          className={clsx(
            'tw-flex tw-flex-wrap tw-w-full',
            direction === 'row'
              ? 'tw-flex-row tw-gap-x-4 tw-gap-y-2'
              : 'tw-flex-col tw-gap-y-2'
          )}
        >
          {options.map(({ label, value, disabled }, index) => (
            <div style={{ width: itemWidth }} key={index}>
              <InputCheckbox
                ref={ref}
                label={label}
                value={value}
                disabled={disabled}
                isInvalid={isInvalid}
              />
            </div>
          ))}
        </div>
      </CheckboxGroup>
    );
  }
);

InputCheckboxGroup.displayName = 'InputCheckboxGroup';
