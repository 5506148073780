import { clsx } from 'clsx';
import { type ChangeEventHandler, useCallback } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldCheckboxProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputCheckbox } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldCheckbox = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  checkboxLabel,
  onAfterChange,
  ...props
}: FormFieldCheckboxProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      field.onChange(e.target.checked);
      onAfterChange && onAfterChange(e.target.checked);
    },
    [onAfterChange, field]
  );

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <div className={clsx('tw-min-h-[40px] tw-flex tw-items-center')}>
        <InputCheckbox
          data-testid={name}
          id={name}
          isChecked={field.value}
          label={checkboxLabel}
          isInvalid={error !== undefined}
          {...inputProps}
          {...{ ...field, onChange: handleChange }}
        />
      </div>
    </FormFieldWrapper>
  );
};
