import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  forwardRef,
} from '@chakra-ui/react';

import type { InputNumberProps } from './type';

import { commonStyle } from '@/components/ui/input/const';

export const InputNumber = forwardRef<InputNumberProps, 'input'>(
  (
    {
      placeholder = '入力してください',
      withStepper = true,
      size = 'md',
      ...inputProps
    },
    ref
  ) => {
    return (
      <NumberInput
        ref={ref}
        {...inputProps}
        {...commonStyle}
        size={size}
        w="full"
      >
        <NumberInputField placeholder={placeholder} rounded="base" />
        {withStepper && (
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        )}
      </NumberInput>
    );
  }
);

InputNumber.displayName = 'InputNumber';
