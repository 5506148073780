import { useController } from 'react-hook-form';

import type { FormFieldNumberTextProps } from './type';
import type { FocusEventHandler } from 'react';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputText } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldNumberText = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  helperText = '半角英数字のみ',
  onBlur,
  ...props
}: FormFieldNumberTextProps<T, U>) => {
  const {
    field: { onBlur: onReactHookFormBlur, ...field },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const convertedStr = e.target.value
      .replaceAll(/[Ａ-Ｚａ-ｚ０-９]/g, (str) => {
        if (!str) return '';
        return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
      })
      .replaceAll(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '');
    field.onChange(convertedStr);
    onReactHookFormBlur();
    if (onBlur !== undefined) {
      onBlur(e);
    }
  };

  return (
    <FormFieldWrapper error={error} {...wrapProps} helperText={helperText}>
      <InputText
        data-testid={name}
        id={name}
        isRequired={wrapProps.isRequired}
        isInvalid={error !== undefined}
        onBlur={handleBlur}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
