import { clsx } from 'clsx';

import type { ActionCompleteModalProps } from './type';

import { BaseModal, Button } from '@/components/ui';

export const ActionCompleteModal = ({
  isOpen,
  title,
  subTitle,
  footer,
  confirmButtonText,
  cancelButtonText = '今はしない',
  onClose,
  onConfirm,
  onCancel,
}: ActionCompleteModalProps) => {
  const handleClose = () => {
    onCancel();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title=""
      closeOnEsc={false}
      closeOnOverlayClick={false}
      withCloseButton={false}
      onClose={onClose}
    >
      <div className={clsx('tw-flex tw-flex-col tw-items-center')}>
        <header className={clsx('tw-mb-6 tw-text-sm tw-font-bold')}>
          {title}
        </header>
        <svg
          width="160"
          height="161"
          viewBox="0 0 160 161"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.29114 145.671L0 147.999L3.89311 139.023L7.29114 145.671Z"
            fill="#FFDC4F"
          />
          <path
            d="M14.6727 143.296L7.29159 145.67L3.89355 139.023L7.80917 129.957L14.6727 143.296Z"
            fill="#F56565"
          />
          <path
            d="M22.0533 140.946L14.6722 143.297L7.80859 129.958L11.7467 120.892L22.0533 140.946Z"
            fill="#FFDC4F"
          />
          <path
            d="M29.4348 138.572L22.0537 140.946L11.7471 120.892L15.6627 111.803L29.4348 138.572Z"
            fill="#F56565"
          />
          <path
            d="M36.8154 136.221L29.4343 138.572L15.6621 111.803L19.6002 102.736L36.8154 136.221Z"
            fill="#FFDC4F"
          />
          <path
            d="M44.1969 133.869L36.8158 136.221L19.6006 102.736L23.5162 93.6475L44.1969 133.869Z"
            fill="#F56565"
          />
          <path
            d="M51.5785 131.495L44.1973 133.869L23.5166 93.6474L27.4547 84.5811L51.5785 131.495Z"
            fill="#FFDC4F"
          />
          <path
            d="M58.959 129.144L51.5779 131.495L27.4541 84.5808L28.3992 82.3877C31.3922 83.4277 34.1602 84.626 36.7481 85.9373L58.959 129.144Z"
            fill="#F56565"
          />
          <path
            d="M66.3402 126.77L58.959 129.144L36.748 85.9375C41.8788 88.515 46.222 91.5446 49.8901 94.7777L66.3402 126.77Z"
            fill="#FFDC4F"
          />
          <path
            d="M69.1086 125.888L66.3407 126.769L49.8906 94.7773C65.7556 108.614 69.1086 125.888 69.1086 125.888Z"
            fill="#F56565"
          />
          <path
            d="M102.414 0.70102L103.539 5.33591C103.607 5.62983 103.832 5.87853 104.102 5.99158L108.535 7.70988C109.255 7.98119 109.345 8.95339 108.692 9.36035L104.642 11.87C104.394 12.0282 104.214 12.2996 104.214 12.6161L103.967 17.3866C103.922 18.1553 103.022 18.5397 102.459 18.0423L98.8359 14.9448C98.6109 14.7414 98.2958 14.6735 98.0033 14.764L93.4126 16.0075C92.6699 16.211 92.0398 15.4648 92.3324 14.764L94.1552 10.3552C94.2677 10.0838 94.2452 9.74471 94.0652 9.49601L91.4773 5.49418C91.0722 4.86112 91.5673 4.00197 92.3099 4.06979L97.0581 4.43154C97.3507 4.45415 97.6432 4.3185 97.8458 4.0924L100.839 0.38449C101.311 -0.203349 102.279 0.000133526 102.459 0.746238L102.414 0.70102Z"
            fill="#FFDC4F"
          />
          <path
            d="M157.209 47.0271L157.322 49.6497C157.322 49.808 157.412 49.9663 157.547 50.0567L159.752 51.4585C160.112 51.6846 160.045 52.2272 159.662 52.3628L157.209 53.2672C157.052 53.335 156.939 53.4481 156.894 53.6289L156.264 56.1838C156.152 56.5908 155.634 56.7038 155.386 56.3647L153.766 54.3072C153.654 54.1716 153.496 54.1037 153.339 54.1264L150.728 54.3072C150.301 54.3298 150.053 53.8777 150.278 53.5159L151.718 51.3228C151.808 51.1872 151.831 51.0063 151.763 50.848L150.796 48.4062C150.638 48.0219 150.998 47.6149 151.403 47.7279L153.924 48.4288C154.081 48.474 154.261 48.4288 154.374 48.3384L156.377 46.6653C156.692 46.394 157.187 46.6201 157.209 47.0271Z"
            fill="#ED8936"
          />
          <path
            d="M83.3305 34.6826L59.3418 84.4907L61.7047 86.2543L89.564 37.4862L83.3305 34.6826Z"
            fill="#FFDC4F"
          />
          <path
            d="M72.9561 96.0213L117.828 69.0938L121.519 78.0922L74.2838 97.2875L72.9561 96.0213Z"
            fill="#F56565"
          />
          <path
            d="M36.4561 75.6282L43.2972 51.1877L37.0412 50.4189L35.8936 75.2439L36.4561 75.6282Z"
            fill="#ED8936"
          />
          <path
            d="M135.983 31.8739L128.522 25.8984L122.575 33.3945L130.036 39.37L135.983 31.8739Z"
            fill="#48BB78"
          />
          <path
            d="M64.8163 11.6292L55.4443 13.5449L57.3511 22.9609L66.7231 21.0452L64.8163 11.6292Z"
            fill="#F56565"
          />
          <path
            d="M144.292 107.141L150.894 98.8389L142.63 92.2064L136.029 100.509L144.292 107.141Z"
            fill="#38B2AC"
          />
          <path
            d="M85.9414 116.46L111.19 117.297L110.043 123.514L86.1664 117.093L85.9414 116.46Z"
            fill="#FFDC4F"
          />
        </svg>
        <main
          className={clsx(
            'tw-flex tw-flex-col tw-items-center tw-mt-6 tw-pt-6 tw-border-t tw-border-gray-200'
          )}
        >
          <h2 className={clsx('tw-text-base tw-font-bold')}>{subTitle}</h2>
          <div className={clsx('tw-mt-6 tw-flex tw-items-center tw-space-x-3')}>
            <Button theme="ghost" onClick={handleClose} isBlock={false}>
              {cancelButtonText}
            </Button>
            <Button theme="solid" onClick={handleConfirm} isBlock={false}>
              {confirmButtonText}
            </Button>
          </div>
        </main>
        {footer !== undefined && (
          <footer className={clsx('tw-mt-6')}>{footer}</footer>
        )}
      </div>
    </BaseModal>
  );
};
