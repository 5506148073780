import { HStack } from '@chakra-ui/react';
import { useCallback, type ChangeEventHandler } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldSelectSingleProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputSelectSingle } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';
import { parseToNumber } from '@/utils/string';

export const FormFieldSelectSingle = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  unit,
  isNumericValue = false,
  onAfterChange,
  ...props
}: FormFieldSelectSingleProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const handleChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      field.onChange(e.target.value);
      onAfterChange && onAfterChange(e.target.value ?? undefined);
    },
    [field, onAfterChange]
  );

  /** NOTE: 返り値をNumber型へ変換 */
  const handleConvertNumericValue: ChangeEventHandler<HTMLSelectElement> =
    useCallback(
      (e) => {
        const value = parseToNumber(e.target.value) ?? 0;

        field.onChange(value);
        onAfterChange && onAfterChange(value);
      },
      [field, onAfterChange]
    );

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <HStack spacing="4">
        <InputSelectSingle
          data-testid={name}
          id={name}
          isRequired={wrapProps.isRequired}
          isInvalid={error !== undefined}
          {...inputProps}
          {...(isNumericValue
            ? { ...field, onChange: handleConvertNumericValue }
            : { ...field, onChange: handleChange })}
        />
        {unit}
      </HStack>
    </FormFieldWrapper>
  );
};
