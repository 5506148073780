import type { AlertProps } from '@chakra-ui/react';

export const REMARK_COLORS: {
  [key in NonNullable<AlertProps['status']>]: string;
} = {
  success: 'tw-bg-green-50',
  warning: 'tw-bg-yellow-50',
  error: 'tw-bg-red-50',
  info: 'tw-bg-blue-50',
  loading: 'tw-bg-yellow-50',
};
