import { clsx } from 'clsx';

import type { AdviceAlertProps } from './type';

export const AdviceAlert = ({ children }: AdviceAlertProps) => {
  return (
    <div
      className={clsx(
        'tw-py-3 tw-px-4 tw-flex tw-items-center tw-bg-yellow-50 tw-gap-x-4',
        'tw-border tw-border-yellow-200 tw-rounded-[8px]'
      )}
    >
      <div className={clsx('tw-flex tw-flex-col tw-items-center tw-gap-y-2')}>
        <svg
          width="16"
          height="24"
          viewBox="0 0 16 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5210_100143)">
            <path
              d="M8 0C3.58 0 0 3.58 0 8C0 8.16 0 8.31 0.01 8.47C0.02 8.59 0.03 8.7 0.04 8.82C0.07 9.09 0.11 9.35 0.16 9.61C0.65 12.33 2.32 14.64 4 18V22C4 23.1 4.89 24 6 24H10C11.1 24 12 23.1 12 22V18C13.68 14.64 15.35 12.33 15.84 9.61C15.89 9.35 15.93 9.09 15.96 8.82C15.97 8.7 15.98 8.59 15.99 8.47C16 8.31 16 8.16 16 8C16 3.58 12.42 0 8 0ZM3.05 8C3.05 8.27 2.83 8.5 2.55 8.5C2.27 8.5 2.05 8.27 2.05 8C2.05 4.72 4.72 2.05 8 2.05C8.27 2.05 8.5 2.27 8.5 2.55C8.5 2.83 8.27 3.05 8 3.05C5.27 3.05 3.05 5.27 3.05 8ZM10 23H6C5.45 23 5 22.55 5 22H11C11 22.55 10.55 23 10 23ZM11 21H5V20H11V21ZM11 19H5V18H11V19Z"
              fill="#D69E2E"
            />
          </g>
        </svg>
        <span className={clsx('tw-text-xs tw-font-bold tw-text-yellow-500')}>
          アドバイス
        </span>
      </div>
      <div className={clsx('tw-text-sm tw-leading-5')}>{children}</div>
    </div>
  );
};
