import {
  ScrollMode,
  SpecialZoomLevel,
  Viewer,
  Worker,
} from '@react-pdf-viewer/core';
import { clsx } from 'clsx';

import type { PdfViewerProps, PdfViewerWorkerProps } from './type';

import { PDFJS_VERSION } from './const';

export const PdfViewer = ({
  src,
  onlyFirstPage,
  onDocumentLoad,
}: PdfViewerProps) => {
  return (
    <Viewer
      defaultScale={SpecialZoomLevel.PageFit}
      setRenderRange={
        onlyFirstPage ? () => ({ startPage: 0, endPage: 0 }) : undefined
      }
      scrollMode={onlyFirstPage ? ScrollMode.Page : undefined}
      fileUrl={src}
      renderError={() => (
        <p
          className={clsx(
            'tw-w-full tw-min-h-full tw-flex tw-items-center tw-justify-center'
          )}
        >
          表示できません
        </p>
      )}
      characterMap={{
        url: `https://unpkg.com/pdfjs-dist@${PDFJS_VERSION}/cmaps/`,
        isCompressed: true,
      }}
      onDocumentLoad={onDocumentLoad}
    />
  );
};

export const PdfViewerWorker = ({ children }: PdfViewerWorkerProps) => {
  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@${PDFJS_VERSION}/legacy/build/pdf.worker.js`}
    >
      {children}
    </Worker>
  );
};
