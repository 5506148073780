import type { ButtonProps } from '@chakra-ui/react';

const themeKeys = [
  'solid',
  'outline',
  'ghost',
  'alert',
  'gray',
  'cta',
] as const;

const disabledBase = {
  opacity: 1,
  cursor: 'not-allowed',
  event: 'none',
};

export const themes: Record<(typeof themeKeys)[number], ButtonProps> = {
  solid: {
    bg: 'primary.500',
    color: 'white',
    _hover: { bg: 'primary.400' },
    _active: { bg: 'primary.600' },
    _disabled: {
      ...disabledBase,
      bg: 'gray.300',
      color: 'gray.500',
      _hover: {
        bg: 'gray.300',
      },
    },
  },
  outline: {
    bg: 'white',
    border: 'solid',
    borderWidth: '1px',
    borderColor: 'gray.400',
    _hover: { bg: 'gray.100' },
    _active: { bg: 'gray.200' },
    _disabled: {
      ...disabledBase,
      color: 'gray.500',
      _hover: {
        bg: 'white',
      },
    },
  },
  ghost: {
    bg: 'transparent',
    _hover: { bg: 'gray.100' },
    _active: { bg: 'gray.200' },
    _disabled: {
      ...disabledBase,
      color: 'gray.700',
      _hover: {
        bg: 'transparent',
      },
    },
  },
  alert: {
    colorScheme: 'red',
  },
  gray: {
    colorScheme: 'gray',
  },
  cta: {
    bg: 'lp.green.400',
    color: 'white',
    _hover: { bg: 'lp.greenAlpha' },
  },
};
