import {} from 'react';
import { Switch } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { InputSwitchProps } from './type';

export const InputSwitch = ({
  label,
  isLeftLabel = false,
  ...inputProps
}: InputSwitchProps) => {
  return (
    <span
      className={clsx(
        'tw-flex tw-items-center tw-gap-x-2',
        isLeftLabel && 'tw-flex-row-reverse tw-justify-end'
      )}
    >
      <Switch {...inputProps} size="md" bg="transparent" />
      <span>{label}</span>
    </span>
  );
};
