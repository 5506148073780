import { Tabs as ChakraTabs } from '@chakra-ui/react';

import type { TabsProps } from './type';

export const Tabs = ({
  children,
  defaultIndex,
  tabIndex,
  onChange,
}: TabsProps) => {
  return (
    <ChakraTabs
      defaultIndex={defaultIndex}
      tabIndex={tabIndex}
      onChange={onChange}
    >
      {children}
    </ChakraTabs>
  );
};
