import { Spinner, Fade } from '@chakra-ui/react';
import { clsx } from 'clsx';
import { useRef } from 'react';

import type { LoadingOverlayProps } from './type';

import { useBodyScrollLock } from '@/hooks/utils';

export const LoadingOverlay = ({ isLoading }: LoadingOverlayProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useBodyScrollLock({ isActive: isLoading, target: ref });

  if (!isLoading) return null;
  return (
    <Fade in={isLoading}>
      <div
        ref={ref}
        className={clsx(
          'tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-opacity-50 tw-z-overlay'
        )}
      >
        <Spinner size="lg" speed="0.6s" />
      </div>
    </Fade>
  );
};
