import { useMemo, useCallback } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldNumberRangeProps } from './type';
import type { FieldError, FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputNumberRange } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';
import { parseToNumber } from '@/utils/string';

import { isNumberRangeFieldError } from './util';

export const FormFieldNumberRange = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...props
}: FormFieldNumberRangeProps<T, U>) => {
  const {
    field: { value, ...restField },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const errors = useMemo(() => {
    return isNumberRangeFieldError(error)
      ? [error?.min, error?.max].filter(
          (item): item is FieldError => item !== undefined
        )
      : [];
  }, [error]);

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  const parsedValue = {
    min: value?.min != null ? value.min : '',
    max: value?.max != null ? value.max : '',
  };

  /** NOTE: 返り値をNumber型へ変換 */
  const handleChange = useCallback(
    ({ min, max }: { min: string; max: string }) =>
      restField.onChange({
        min: parseToNumber(min) ?? null,
        max: parseToNumber(max) ?? null,
      }),
    [restField]
  );

  return (
    <FormFieldWrapper error={errors} {...wrapProps}>
      <InputNumberRange
        data-testid={name}
        id={name}
        value={parsedValue}
        isRequired={wrapProps.isRequired}
        isInvalid={error !== undefined}
        {...inputProps}
        {...restField}
        onChange={handleChange}
      />
    </FormFieldWrapper>
  );
};
