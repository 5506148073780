import { Checkbox, forwardRef } from '@chakra-ui/react';

import type { InputCheckboxProps } from './type';

import { commonStyle } from '@/components/ui/input/const';

export const InputCheckbox = forwardRef<InputCheckboxProps, 'input'>(
  ({ label, ...inputProps }, ref) => {
    return (
      <Checkbox
        ref={ref}
        {...inputProps}
        {...commonStyle}
        size="md"
        bg="transparent"
      >
        {label}
      </Checkbox>
    );
  }
);

InputCheckbox.displayName = 'InputCheckbox';
