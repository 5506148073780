import { Delete } from '@mui/icons-material';
import { clsx } from 'clsx';

import type { DeleteButtonProps } from './type';

export const DeleteButton = ({
  ariaLabel = '削除',
  type,
  onClick,
}: DeleteButtonProps) => {
  return (
    <button
      type={type}
      aria-label={ariaLabel}
      onClick={onClick}
      className={clsx(
        'tw-flex tw-items-center tw-justify-center tw-text-gray-500',
        'hover:tw-text-gray-600 tw-transition tw-duration-200'
      )}
    >
      <Delete />
    </button>
  );
};
