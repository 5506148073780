import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { clsx } from 'clsx';

import type { TablePaginationProps } from './type';
import type { ChangeEvent } from 'react';

import { IconButton, InputSelectSingle } from '@/components/ui';
import { parseToNumber } from '@/utils/string';

import { PER_PAGE_OPTIONS } from './const';

export const TablePagination = ({
  count,
  perPage,
  page,
  withPerPageSelector = true,
  onPageChange,
}: TablePaginationProps) => {
  const currentPageFirstIndex = (page - 1) * perPage + 1;
  const currentPageLastIndex = Math.min(
    currentPageFirstIndex + perPage - 1,
    count
  );

  const onClickNext = () => {
    onPageChange({ count, perPage, page: page + 1 });
  };
  const onClickPrev = () => {
    onPageChange({ count, perPage, page: page - 1 });
  };
  const handleChangePerPage = (event: ChangeEvent<HTMLSelectElement>) => {
    const numerizedPerPage = parseToNumber(event.target.value);
    if (numerizedPerPage === undefined) return;
    onPageChange({ count, perPage: numerizedPerPage, page: 1 });
  };

  const isCurrentPageFirst = page === 1;
  const isCurrentPageLast = page === Math.ceil(count / perPage);

  return (
    <div className="tw-px-6 tw-py-3 tw-flex tw-items-center tw-justify-between">
      {withPerPageSelector ? (
        <div className={clsx('tw-flex tw-items-center tw-space-x-2')}>
          <label className={clsx('tw-text-sm tw-whitespace-nowrap')}>
            表示件数
          </label>
          <InputSelectSingle
            size="sm"
            value={perPage}
            options={PER_PAGE_OPTIONS}
            placeholder={''}
            onChange={handleChangePerPage}
          />
        </div>
      ) : (
        <div />
      )}
      <div className="tw-flex tw-items-center tw-gap-x-6">
        <div className="tw-flex-none tw-text-sm">
          <span className="tw-text-gray-700">
            {currentPageFirstIndex}-{currentPageLastIndex}
          </span>
          <span className="tw-text-gray-500">{` of ${count}`}</span>
        </div>
        <div className="!tw-text-gray-900 tw-flex-none">
          <IconButton
            aria-label="PrevButton"
            icon={<ChevronLeftIcon />}
            backgroundColor="white"
            onClick={onClickPrev}
            isDisabled={isCurrentPageFirst}
          />
          <IconButton
            aria-label="NextButton"
            icon={<ChevronRightIcon />}
            backgroundColor="white"
            onClick={onClickNext}
            isDisabled={isCurrentPageLast}
          />
        </div>
      </div>
    </div>
  );
};
