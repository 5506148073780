export const parseToNumber = (input: unknown) => {
  const isNaN = Number.isNaN(Number(input));
  if (isNaN) return undefined;
  return input === '' ? undefined : Number(input);
};

export const trimString = (input: string, length: number) => {
  if (input.length <= length) return input;
  return `${input.slice(0, length)}…`;
};

export const validateKana = (target: string) => /^[ァ-ヴー]*$/.test(target);

export const upperCaseOf = <T extends string>(str: T) => {
  type Mapped = Uppercase<T>;
  return str.toUpperCase() as Mapped;
};

export const lowerCaseOf = <T extends string>(str: T) => {
  type Mapped = Lowercase<T>;
  return str.toLowerCase() as Mapped;
};
