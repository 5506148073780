import type { FieldError } from 'react-hook-form';

export const isNumberRangeFieldError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any // FIXME: anyやめたい
): error is { min: FieldError | undefined; max: FieldError | undefined } => {
  if (error === undefined) return true;

  if ('min' in error || 'max' in error) {
    return true;
  }
  return false;
};
