import { IconButton as ChakraIconButton, forwardRef } from '@chakra-ui/react';

import type { IconButtonProps } from './type';

import { fontSizes } from './const';

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  ({ size = 'sm', ...props }, ref) => {
    return (
      <ChakraIconButton
        ref={ref}
        colorScheme="gray"
        size={size}
        fontSize={fontSizes[size]}
        {...props}
      />
    );
  }
);
