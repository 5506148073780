import { Tooltip } from '@chakra-ui/react';
import { Help } from '@mui/icons-material';

import type { HelpTooltipProps } from './type';

export const HelpTooltip = ({
  label,
  placement = 'right',
  hasArrow = true,
}: HelpTooltipProps) => {
  return (
    <Tooltip
      label={label}
      placement={placement}
      hasArrow={hasArrow}
      p="2"
      rounded="base"
      bg="gray.900"
    >
      <Help sx={{ color: '#838795' }} />
    </Tooltip>
  );
};
