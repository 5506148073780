import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { clsx } from 'clsx';

import type { TableProps } from './type';

export const Table = <T extends object>({
  tableWidth,
  headers,
  rightHeaders,
  rows,
  onRowClick,
}: TableProps<T>) => {
  return (
    <div className={clsx('tw-overflow-x-auto')}>
      <ChakraTable style={{ width: tableWidth }}>
        <Thead className="tw-bg-gray-50">
          <Tr className={clsx('tw-w-fit')}>
            {headers.map((header, index) => (
              <Th
                key={`${header.id}_${index}`}
                px={2}
                style={{
                  width: header.getSize(),
                }}
                textTransform="none"
                className={clsx('tw-relative')}
              >
                <div
                  className={clsx(
                    'tw-flex tw-items-center tw-text-gray-700',
                    header.column.getCanSort() && 'tw-cursor-pointer'
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  {header.column.getCanResize() && (
                    <div
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={clsx(
                        'tw-absolute tw-right-0 tw-inset-y-[20%] tw-w-2 tw-cursor-col-resize tw-select-none tw-touch-none',
                        'tw-border-r-2 tw-border-gray-300',
                        'hover:tw-inset-y-0 hover:tw-border-r-4 hover:tw-border-blue-500',
                        header.column.getIsResizing() &&
                          'tw-inset-y-0 tw-border-blue-500 tw-opacity-100'
                      )}
                    />
                  )}
                </div>
              </Th>
            ))}
            {rightHeaders !== undefined &&
              rightHeaders.map((header, index) => (
                <Th
                  key={`${header.id}_${index}`}
                  px={2}
                  style={{
                    width:
                      header.column.columnDef.size !== undefined
                        ? `${header.column.columnDef.size}px`
                        : undefined,
                  }}
                  textTransform="none"
                  className={clsx(
                    'tw-absolute tw-h-10 tw-right-0 tw-bg-gray-50'
                  )}
                >
                  <div
                    className={clsx(
                      'tw-flex tw-items-center tw-text-gray-700',
                      header.column.getCanSort() && 'tw-cursor-pointer'
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </Th>
              ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row) => (
            <Tr
              key={row.id}
              onClick={
                onRowClick !== undefined ? () => onRowClick(row) : undefined
              }
              className={clsx(
                'tw-w-fit',
                onRowClick !== undefined
                  ? 'tw-cursor-pointer hover:tw-opacity-60 tw-transition-opacity'
                  : undefined
              )}
            >
              {row.getCenterVisibleCells().map((cell, index) => (
                <Td
                  padding={0}
                  key={`${cell.id}_${index}`}
                  className={clsx(
                    'tw-relative',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (cell.column.columnDef.meta as any)?.withBorderRight &&
                      'tw-border-r tw-border-gray-400'
                  )}
                  style={{ width: cell.column.getSize() }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  <div
                    className={clsx(
                      !cell.column.getIsResizing() && 'tw-hidden',
                      'tw-absolute tw-right-0.5 tw-top-0 tw-h-full tw-w-[1px] tw-bg-blue-500 tw-select-none tw-touch-none'
                    )}
                  />
                </Td>
              ))}
              {row.getRightVisibleCells().map((cell, index) => (
                <Td
                  padding={0}
                  key={`${cell.id}_${index}`}
                  className={clsx(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (cell.column.columnDef.meta as any)?.withBorderRight &&
                      'tw-border-r tw-border-gray-400',
                    'tw-absolute tw-right-0 tw-bg-white tw-border-0 tw-border-t tw-border-gray-200'
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </div>
  );
};
