import { Tabs, useTab } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { SwitchTabsProps } from './type';
import type { TabProps } from '@chakra-ui/react';

import { COLORS } from './const';

export const SwitchTabs = ({
  tabs,
  size = 'md',
  withBorder = true,
  customTextColor = 'blue',
  isDisabled,
  defaultIndex,
  index,
  onChange,
}: SwitchTabsProps) => {
  return (
    <Tabs
      className="tw-w-full"
      variant="soft-rounded"
      colorScheme="blue"
      defaultIndex={defaultIndex}
      index={index}
      onChange={onChange}
    >
      <div
        className={clsx(
          'tw-flex tw-gap-x-2 tw-rounded-[8px] tw-bg-gray-300',
          withBorder && 'tw-border tw-border-gray-400',
          size === 'md' ? 'tw-p-1' : 'tw-p-1.5',
          isDisabled && 'tw-opacity-40'
        )}
      >
        {tabs.map((tab, index) => (
          <CustomTab
            isDisabled={isDisabled || tab.isDisabled}
            customTextColor={customTextColor}
            size={size}
            key={index}
          >
            {tab.label}
          </CustomTab>
        ))}
      </div>
    </Tabs>
  );
};

const CustomTab = (
  props: TabProps & {
    size: SwitchTabsProps['size'];
    customTextColor: SwitchTabsProps['customTextColor'];
  }
) => {
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];

  return (
    <div
      {...tabProps}
      className={clsx(
        'tw-w-1/2 tw-font-bold tw-text-center tw-select-none',
        isSelected &&
          `tw-bg-white tw-rounded-md tw-outline-none ${COLORS[props.customTextColor ?? 'black'].selectedText}`,
        !isSelected && `${COLORS[props.customTextColor ?? 'black'].text}`,
        props.size === 'md' ? 'tw-text-base tw-py-2' : 'tw-text-sm tw-py-1',
        props.isDisabled && 'tw-cursor-not-allowed',
        !props.isDisabled && !isSelected && 'tw-cursor-pointer'
      )}
      style={{
        boxShadow: isSelected
          ? '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
          : '',
      }}
    >
      {tabProps.children}
    </div>
  );
};
