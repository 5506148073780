import {
  Accordion as BaseAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';

import type { AccordionProps } from './type';

export const Accordion = ({ label, children, ...props }: AccordionProps) => {
  return (
    <BaseAccordion allowToggle {...props}>
      <AccordionItem border={'none'}>
        <AccordionButton px={1} py={1}>
          <AccordionIcon fontSize={'2xl'} color={'gray.700'} />
          <div className="tw-text-xl tw-font-bold tw-pl-1">{label}</div>
        </AccordionButton>
        <AccordionPanel px={1} py={0}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </BaseAccordion>
  );
};
