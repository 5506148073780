import type { FormFieldWrapperPassThroughProps } from './type';

export const extractWrapperProps = <T extends FormFieldWrapperPassThroughProps>(
  props: T
) => {
  switch (props.wrapType) {
    case 'horizontal': {
      const {
        id,
        wrapType,
        label,
        helperText,
        isRequired,
        showError,
        showLabel,
        labelMinWidth,
        labelTooltipText,
        ...rest
      } = props;
      return {
        wrapProps: {
          id,
          wrapType,
          label,
          helperText,
          isRequired,
          showError,
          showLabel,
          labelMinWidth,
          labelTooltipText,
        },
        rest,
      };
    }
    case 'vertical': {
      const {
        id,
        wrapType,
        label,
        helperText,
        isRequired,
        showError,
        showLabel,
        isBoldLabel,
        ...rest
      } = props;
      return {
        wrapProps: {
          id,
          wrapType,
          label,
          helperText,
          isRequired,
          showError,
          showLabel,
          isBoldLabel,
        },
        rest,
      };
    }
  }
};
