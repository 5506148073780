import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { clsx } from 'clsx';
import { useMemo } from 'react';

import type { FormFieldHorizontalWrapperProps } from './type';

import { HelpTooltip } from '@/components/ui';

export const FormFieldHorizontalWrapper = ({
  id,
  label,
  labelTooltipText,
  labelMinWidth = '238px',
  helperText,
  error,
  showLabel = true,
  showError = true,
  isRequired = false,
  isBoldLabel = false,
  children,
}: FormFieldHorizontalWrapperProps) => {
  const displayError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  );

  return (
    <FormControl isRequired={isRequired} isInvalid={error !== undefined}>
      <div className={clsx('tw-flex tw-flex-row tw-items-start tw-space-x-4')}>
        {showLabel && (
          <div
            className={clsx('tw-flex tw-flex-row tw-items-center tw-space-x-1')}
            style={{ minWidth: labelMinWidth }}
          >
            <FormLabel
              htmlFor={id}
              m={0}
              mt={2}
              fontWeight={isBoldLabel ? 'bold' : 'normal'}
            >
              {label}
            </FormLabel>
            {labelTooltipText && <HelpTooltip label={labelTooltipText} />}
          </div>
        )}
        <div className={clsx('tw-flex-1')}>
          {children}
          {showError && (
            <FormErrorMessage>{displayError?.message}</FormErrorMessage>
          )}
          {helperText !== undefined && (
            <FormHelperText as="div" color="gray.600">
              {helperText}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  );
};
