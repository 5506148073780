import { Button, forwardRef, useDisclosure } from '@chakra-ui/react';
import { Add } from '@mui/icons-material';
import { clsx } from 'clsx';
import { useState, useEffect, useMemo } from 'react';

import type { InputModalCheckboxGroupProps } from './type';

import { ActionModal, InputCheckboxGroup } from '@/components/ui';

import { themes } from './const';

export const InputModalCheckboxGroup = forwardRef<
  InputModalCheckboxGroupProps,
  'button'
>(
  (
    {
      value,
      options,
      placeholder = '選択する',
      title,
      description,
      direction,
      isDisabled,
      isInvalid,
      onChange,
      ...checkboxGroupProps
    },
    ref
  ) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [localValue, setLocalValue] = useState<
      InputModalCheckboxGroupProps['value']
    >([]);

    const selectedValueText = useMemo(() => {
      if (value === undefined || value.length === 0) return placeholder;
      return value
        .map((v) => options.find((option) => option.value === v)?.label)
        .join(', ');
    }, [options, placeholder, value]);

    /** modal内のcheckbox選択処理 */
    const handleCheck: InputModalCheckboxGroupProps['onChange'] = (
      inputValue
    ) => {
      setLocalValue(inputValue);
    };

    /** modal内確定ボタン押下処理 */
    const handleConfirm = () => {
      onChange && onChange(localValue ?? []);
      onClose();
    };

    useEffect(() => {
      setLocalValue(value);
    }, [isOpen, value]);

    return (
      <>
        <Button
          ref={ref}
          height="full"
          py="7px"
          width="full"
          color="gray.700"
          fontWeight="medium"
          rightIcon={
            <Add fontSize="inherit" className={clsx('tw-text-gray-600')} />
          }
          {...(isInvalid ? themes.invalid : themes.valid)}
          isDisabled={isDisabled}
          onClick={onOpen}
        >
          <p
            className={clsx(
              'tw-w-full tw-leading-6 tw-text-left tw-whitespace-pre-wrap'
            )}
          >
            {selectedValueText}
          </p>
        </Button>

        <ActionModal
          isOpen={isOpen}
          title={title}
          confirmButtonText="決定"
          cancelButtonText="キャンセル"
          withCloseButton={false}
          onCancel={onClose}
          onConfirm={handleConfirm}
          onClose={onClose}
        >
          <p className={clsx('tw-mb-2.5')}>{description}</p>
          <InputCheckboxGroup
            value={localValue}
            options={options}
            direction={direction}
            isInvalid={false}
            onChange={handleCheck}
            {...checkboxGroupProps}
          />
        </ActionModal>
      </>
    );
  }
);
