import { clsx } from 'clsx';
import { useController } from 'react-hook-form';

import type { FormFieldCheckboxGroupProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputCheckboxGroup } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldCheckboxGroup = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...props
}: FormFieldCheckboxGroupProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });
  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      {inputProps.options.length > 0 && (
        <div className={clsx('tw-min-h-[40px] tw-flex tw-items-center')}>
          <InputCheckboxGroup
            data-testid={name}
            isInvalid={error !== undefined}
            {...inputProps}
            {...field}
          />
        </div>
      )}
    </FormFieldWrapper>
  );
};
