import { TabList as ChakraTabList, Tab } from '@chakra-ui/react';

import type { TabListProps } from './type';

export const TabList = ({ tabs }: TabListProps) => {
  return (
    <ChakraTabList borderBottom="0">
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          isDisabled={tab?.isDisabled}
          flex="0 0 auto"
          color="gray.600"
          opacity={tab?.isDisabled ? 0.4 : 1}
        >
          {tab.label}
        </Tab>
      ))}
    </ChakraTabList>
  );
};
