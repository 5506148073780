import { useController } from 'react-hook-form';

import type { FormFieldModalCheckboxGroupProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputModalCheckboxGroup } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldModalCheckboxGroup = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...props
}: FormFieldModalCheckboxGroupProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputModalCheckboxGroup
        data-testid={name}
        id={name}
        isInvalid={error !== undefined}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
