import { clsx } from 'clsx';

import type { StepsProps } from './type';

export const Steps = ({ steps, currentIndex, className }: StepsProps) => {
  return (
    <ul className={clsx('tw-flex tw-space-x-3 md:tw-space-x-6', className)}>
      {steps.map(({ label }, index) => (
        <li
          key={`step-${index}`}
          className={clsx(
            'tw-flex-1 tw-flex tw-flex-col tw-border-t-4 tw-pt-4',
            index <= currentIndex ? 'tw-border-black' : 'tw-border-gray-300'
          )}
        >
          <span
            className={clsx(
              'tw-text-xs tw-font-bold tw-tracking-wide',
              index <= currentIndex ? 'tw-text-black' : 'tw-text-gray-500'
            )}
          >{`STEP ${index + 1}`}</span>
          <span className={clsx('tw-text-sm')}>{label}</span>
        </li>
      ))}
    </ul>
  );
};
