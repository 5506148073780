import { Tooltip as ChakraTooltip } from '@chakra-ui/react';

import type { TooltipProps } from './type';

export const Tooltip = ({
  label,
  placement,
  hasArrow = true,
  isDisabled = false,
  children,
}: TooltipProps) => {
  return (
    <ChakraTooltip
      label={label}
      placement={placement}
      hasArrow={hasArrow}
      p="2"
      rounded="base"
      bg="gray.900"
      isDisabled={isDisabled}
    >
      {children}
    </ChakraTooltip>
  );
};
