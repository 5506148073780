import { clsx } from 'clsx';
import { useCallback, forwardRef } from 'react';

import type { InputNumberRangeProps } from './type';

import { InputNumber } from '@/components/ui';

export const InputNumberRange = forwardRef<
  HTMLInputElement,
  InputNumberRangeProps
>(
  (
    {
      id,
      name,
      value,
      placeholder,
      unit,
      subLabel,
      onChange,
      ...commonInputNumberProps
    },
    ref
  ) => {
    const handleChange = useCallback(
      (key: keyof typeof value, input: string) => {
        onChange({
          ...value,
          [key]: input,
        });
      },
      [value, onChange]
    );

    return (
      <div className={clsx('tw-flex tw-items-center')}>
        {subLabel && subLabel.min && (
          <span className={clsx('tw-mr-2 tw-flex-shrink-0')}>
            {subLabel.min}
          </span>
        )}
        <InputNumber
          ref={ref}
          id={`${id}-min`}
          name={`${name}-min`}
          value={value.min}
          placeholder={placeholder?.min ?? '下限'}
          onChange={(input) => handleChange('min', input)}
          {...commonInputNumberProps}
        />
        {unit !== undefined && <span className={clsx('tw-ml-2')}>{unit}</span>}

        <span className={clsx('tw-mx-4', 'tw-text-xs')}>～</span>

        {subLabel && subLabel.max && (
          <span className={clsx('tw-mr-2 tw-flex-shrink-0')}>
            {subLabel.max}
          </span>
        )}
        <InputNumber
          id={`${id}-max`}
          name={`${name}-max`}
          value={value.max}
          placeholder={placeholder?.max ?? '上限'}
          onChange={(input) => handleChange('max', input)}
          {...commonInputNumberProps}
        />
        {unit !== undefined && <span className={clsx('tw-ml-2')}>{unit}</span>}
      </div>
    );
  }
);

InputNumberRange.displayName = 'InputNumberRange';
