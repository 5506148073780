import {
  format,
  addDays,
  min,
  max,
  getYear,
  getMonth,
  getDate,
  setHours,
  setMinutes,
  isSameDay,
  isAfter,
  isEqual,
  lastDayOfMonth,
  subDays,
  setSeconds,
} from 'date-fns';
import { ja } from 'date-fns/locale';

export const formatDate = (
  date: string | number | Date | null,
  template = 'yyyy-MM-dd hh:mm'
) => {
  if (date === null) return undefined;
  if (!isValidDate(date)) return 'Invalid Date';

  return format(new Date(date), template, {
    locale: ja,
  });
};

export const formatDateToAPIDateTime = (date: string | number | Date | null) =>
  `${formatDate(date, 'yyyy-MM-dd')}T${formatDate(date, 'HH:mm:ss')}.000Z`;

/**
 * dayCount分日数を足したDateを取得する
 */
export const addDaysToDate = (
  date: string | number | Date,
  dayCount: number
) => {
  return addDays(new Date(date), dayCount);
};

export const subDaysToDate = (
  date: string | number | Date,
  dayCount: number
) => {
  return subDays(new Date(date), dayCount);
};

export const getMinDate = (
  dates: Array<string | number | Date | null | undefined>
) => {
  const filteredDates = dates.flatMap((date) =>
    !date ? [] : [new Date(date)]
  );
  return min(filteredDates);
};

export const getMaxDate = (
  dates: Array<string | number | Date | null | undefined>
) => {
  const filteredDates = dates.flatMap((date) =>
    !date ? [] : [new Date(date)]
  );
  return max(filteredDates);
};

export const getThisYear = () => {
  return getYear(new Date());
};

export const setTimesToDate = (
  date: string | number | Date,
  hours = 0,
  minutes = 0,
  seconds = 0
) => {
  return setHours(
    setMinutes(setSeconds(new Date(date), seconds), minutes),
    hours
  );
};

export const resetHHmm = (date: Date) => {
  return setHours(setMinutes(date, 0), 0);
};

export const resetHHmmss = (date: Date) => {
  return setHours(setMinutes(setSeconds(date, 0), 0), 0);
};

export const isValidDate = (dateStr: string | number | Date): boolean => {
  try {
    const d = new Date(dateStr);
    return !Number.isNaN(d.getDate());
  } catch (_) {
    return false;
  }
};

export const checkIsSameDay = (
  date: Date | number,
  comparison: Date | number
) => isSameDay(date, comparison);

export const checkIsEqualAfter = (
  date: Date | number,
  comparison: Date | number
) => isEqual(date, comparison) || isAfter(date, comparison);

export const getLastDayOfMonth = (date: Date) => lastDayOfMonth(date);

export const parseDateStr = (
  dateStr: `${number}${number}${number}${number}-${number}${number}-${number}${number}`
) => {
  const date = new Date(dateStr);
  return {
    year: getYear(date),
    month: getMonth(date) + 1,
    date: getDate(date),
  };
};
