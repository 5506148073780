import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Add } from '@mui/icons-material';

import type { ButtonMenuProps } from './type';

import { Link } from '@/components/ui';
import { Button } from '@/components/ui';

export const ButtonMenu = ({
  buttonText,
  menuItems,
  isDisabled = false,
  isLoading = false,
}: ButtonMenuProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        theme="solid"
        leftIcon={
          <Add
            fontSize="inherit"
            sx={{ color: !isDisabled ? 'black' : 'gray.500' }}
          />
        }
        isBlock={false}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        {buttonText}
      </MenuButton>
      <MenuList minWidth="auto" zIndex="popover">
        {menuItems.map((menuItem, index) =>
          menuItem.type === 'button' ? (
            <MenuItem
              key={`${menuItem.type}_${index}`}
              onClick={menuItem.onClick}
              isDisabled={menuItem.isDisabled}
            >
              {menuItem.label}
            </MenuItem>
          ) : (
            <MenuItem
              key={`${menuItem.type}_${index}`}
              isDisabled={menuItem.isDisabled}
            >
              {menuItem.isDisabled ? (
                menuItem.label
              ) : (
                <Link href={menuItem.href}>{menuItem.label}</Link>
              )}
            </MenuItem>
          )
        )}
      </MenuList>
    </Menu>
  );
};
