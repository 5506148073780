import { CalendarToday } from '@mui/icons-material';
import ja from 'date-fns/locale/ja';
import { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import type { InputDateProps } from './type';

import { InputText } from '@/components/ui';
import { formatDate } from '@/utils/date';

registerLocale('ja', ja);

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  (
    {
      value,
      onChange,
      onFocus,
      onBlur,
      closeOnScroll = true,
      dateFormat = 'yyyy-MM-dd',
      isDisabled,
      minDate,
      maxDate,
      filterTime,
      withTimePicker = false,
      timeIntervals = 30,
      placeholder,
      ...inputTextProps
    },
    ref
  ) => {
    const timePickerProps = withTimePicker
      ? {
          showTimeSelect: true,
          timeFormat: 'HH:mm',
          timeCaption: '時刻',
        }
      : {};

    return (
      <DatePicker
        selected={value ? new Date(value) : undefined}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholderText={placeholder}
        customInput={
          <InputText
            inputRef={ref} // HACK: refに渡すと上手く参照できない
            isDisabled={isDisabled}
            value={formatDate(value ?? null, dateFormat)}
            icon={<CalendarToday fontSize="small" />}
            {...inputTextProps}
          />
        }
        closeOnScroll={closeOnScroll}
        disabled={isDisabled}
        locale="ja"
        dateFormat={dateFormat}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        filterTime={filterTime}
        showPopperArrow={false}
        timeIntervals={timeIntervals}
        {...timePickerProps}
      />
    );
  }
);

InputDate.displayName = 'InputDate';
