export const isSequentialSortOrders = (
  sortable: readonly { sortOrder: number }[]
) => {
  const sorted = [...sortable].sort((a, b) => a.sortOrder - b.sortOrder);
  for (let i = 0; i < sorted.length; i++) {
    if (sorted[i].sortOrder !== i + 1) {
      return false;
    }
  }
  return true;
};
