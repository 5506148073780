import type { ObjectFit } from './type';

export const NO_IMAGE_SRC = '/images/img-noimage.png';

export const IMAGE_DELIVERY_DOMAIN =
  process.env.NEXT_PUBLIC_IMAGE_DELIVERY_DOMAIN;
export const IMAGE_ORIGINAL_DOMAIN =
  process.env.NEXT_PUBLIC_IMAGE_ORIGINAL_DOMAIN;

export const OBJECT_FIT = {
  scaleDown: 'scale-down',
  contain: 'contain',
  cover: 'cover',
} as const;

export const OBJECT_FIT_CLASS_NAMES = (objectFit: ObjectFit) => {
  switch (objectFit) {
    case 'scale-down':
      return 'tw-object-scale-down';
    case 'contain':
      return 'tw-object-contain';
    case 'cover':
      return 'tw-object-cover';
  }
};
