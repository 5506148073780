import { Button as ChakraButton } from '@chakra-ui/react';
import { forwardRef } from 'react';

import type { ButtonProps } from './type';

import { themes } from './const';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { size = 'md', type = 'button', theme = 'solid', isBlock = true, ...props },
    ref
  ) => {
    return (
      <ChakraButton
        ref={ref}
        type={type}
        size={size}
        width={isBlock ? 'full' : undefined}
        minWidth="max-content"
        whiteSpace="nowrap"
        {...themes[theme]}
        {...props}
      />
    );
  }
);

Button.displayName = 'Button';
