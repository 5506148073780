import type { Colors } from './type';

export const COLORS: Record<Colors, { text: string; selectedText: string }> = {
  black: {
    text: 'tw-text-gray-600',
    selectedText: 'tw-text-black',
  },
  blue: {
    text: 'tw-text-blue-500',
    selectedText: 'tw-text-blue-500',
  },
};
