import { Textarea, forwardRef } from '@chakra-ui/react';

import type { InputTextareaProps } from './type';

import { commonStyle } from '@/components/ui/input/const';

export const InputTextarea = forwardRef<InputTextareaProps, 'textarea'>(
  (
    { placeholder = '入力してください', resize = 'none', ...inputProps },
    ref
  ) => {
    return (
      <Textarea
        ref={ref}
        placeholder={placeholder}
        resize={resize}
        variant={inputProps.isReadOnly ? 'unstyled' : 'outline'}
        {...inputProps}
        {...commonStyle}
        size="md"
      />
    );
  }
);

InputTextarea.displayName = 'InputTextarea';
