import { clsx } from 'clsx';

import type { UsageFlowProps } from './type';

import { Paper } from '@/components/ui';

export const UsageFlow = ({ title, steps }: UsageFlowProps) => {
  return (
    <Paper>
      <div className={clsx('tw-w-64')}>
        <h2 className={clsx('tw-text-lg tw-font-bold')}>{title}</h2>
        <ul className={clsx('tw-mt-4')}>
          {steps.map(({ heading, description }, index) => (
            <li key={heading} className={clsx('tw-flex tw-space-x-3')}>
              <div className={clsx('tw-w-3')}>
                <div className={clsx('tw-h-1 ')}>
                  {index !== 0 ? (
                    <div
                      className={clsx(
                        'tw-mx-auto tw-w-0.5 tw-h-full tw-bg-green-500'
                      )}
                    />
                  ) : null}
                </div>
                <div
                  className={clsx(
                    'tw-w-3 tw-h-3 tw-border-4 tw-border-green-500 tw-rounded-circle'
                  )}
                />
                <div className={clsx('tw-h-full')}>
                  {index !== steps.length - 1 ? (
                    <div
                      className={clsx(
                        'tw-mx-auto tw-w-0.5 tw-h-[calc(100%-12px)] tw-bg-green-500'
                      )}
                    />
                  ) : null}
                </div>
              </div>
              <div className={clsx('tw-flex-1 tw-pb-4')}>
                <h3 className={clsx('tw-text-sm tw-font-bold')}>{heading}</h3>
                <p
                  className={clsx('tw-mt-1 tw-text-xs tw-whitespace-pre-wrap')}
                >
                  {description}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Paper>
  );
};
