import { Link as ChakraLink } from '@chakra-ui/react';
import { forwardRef } from 'react';

import type { LinkProps } from '@chakra-ui/react';

import { HeadlessLink } from '@/components/ui';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { href = '#', children, ...props },
  ref
) {
  return (
    <ChakraLink as={HeadlessLink} href={href} ref={ref} {...props}>
      {children}
    </ChakraLink>
  );
});
