import { Link as ChakraLink } from '@chakra-ui/react';
import { forwardRef } from 'react';

import type { ButtonLinkProps } from './type';

import { HeadlessLink } from '@/components/ui';
import { Button } from '@/components/ui';

export const ButtonLink = forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      href = '#',
      isExternal,
      children,
      download,
      prefetch = true,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <ChakraLink
        as={HeadlessLink}
        href={href}
        ref={ref}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'norefferer' : undefined}
        download={download}
        prefetch={prefetch}
        isExternal={isExternal}
        pointerEvents={buttonProps.isDisabled ? 'none' : undefined}
        _hover={{ textDecoration: 'none' }}
      >
        <Button as="span" {...buttonProps}>
          {children}
        </Button>
      </ChakraLink>
    );
  }
);

ButtonLink.displayName = 'ButtonLink';
