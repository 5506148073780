import { clsx } from 'clsx';
import { useController } from 'react-hook-form';

import type { FormFieldRadioGroupProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputRadioGroup } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldRadioGroup = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  onAfterChange,
  ...props
}: FormFieldRadioGroupProps<T, U>) => {
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  const handleChange: FormFieldRadioGroupProps<T, U>['onChange'] = (value) => {
    onChange(value);
    if (onAfterChange !== undefined) {
      onAfterChange(value);
    }
  };

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      {inputProps.options.length > 0 && (
        <div className={clsx('tw-min-h-[40px] tw-flex tw-items-center')}>
          <InputRadioGroup
            data-testid={name}
            id={name}
            onChange={handleChange}
            {...inputProps}
            {...field}
          />
        </div>
      )}
    </FormFieldWrapper>
  );
};
