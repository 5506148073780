import type { FieldError } from 'react-hook-form';

export const isDateRangeFieldError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any // FIXME: anyやめたい
): error is { start: FieldError | undefined; end: FieldError | undefined } => {
  if (error === undefined) return true;

  if ('start' in error || 'end' in error) {
    return true;
  }
  return false;
};
