import { clsx } from 'clsx';
import { useEffect } from 'react';
import { useFieldArray, useController, useWatch } from 'react-hook-form';

import type { FormFieldFileArrayProps } from './type';
import type { File } from '@/components/ui/input/InputFile/type';

import { FormFieldWrapper, FormFieldFile } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldFileArray = ({
  name,
  control,
  shouldUnregister,
  defaultValue,
  max,
  ...props
}: FormFieldFileArrayProps) => {
  const { fields, append, remove } = useFieldArray({
    name,
    control,
    shouldUnregister,
  });
  const {
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps } = extractWrapperProps(props);

  const files: Array<File | null> | null | undefined = useWatch({
    name,
    control,
  });

  useEffect(() => {
    if (!files) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      append(null as any);
      return;
    }
    const selectedFileCount = files.reduce(
      (sum, file) => (sum += file != null ? 1 : 0),
      0
    );
    if (selectedFileCount === files.length) {
      // ファイル選択欄が全て埋まっている場合は選択欄追加
      if (max !== undefined) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        files.length < max && append(null as any);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        append(null as any);
      }
    } else if (selectedFileCount < files.length - 1) {
      // ファイル選択欄が2つ以上空いている場合は、1つ選択欄を削除
      const emptyIndex = files.findIndex((file) => file == null);
      remove(emptyIndex);
    }
  }, [files, max, append, remove]);

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <ul className={clsx('tw-space-y-2')}>
        {fields.map((field, index) => (
          <li key={field.id}>
            <FormFieldFile
              wrapType={'horizontal'}
              name={`${name}.${index}`}
              control={control}
              showLabel={false}
            />
          </li>
        ))}
      </ul>
    </FormFieldWrapper>
  );
};
