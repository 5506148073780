import NextLink from 'next/link';

import type { HeadlessLinkProps } from './type';

import { useVersionStore } from '@/stores';
import { stringifyUrl } from '@/utils/url';

export const HeadlessLink = (props: HeadlessLinkProps) => {
  const { requiresRefresh } = useVersionStore();

  return requiresRefresh ? (
    <a
      {...props}
      href={
        typeof props.href === 'string'
          ? props.href
          : stringifyUrl(props.href) ?? ''
      }
    />
  ) : (
    <NextLink {...props} />
  );
};
