import { useMemo } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldDateRangeProps } from './type';
import type { FieldError, FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputDateRange } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

import { isDateRangeFieldError } from './util';

export const FormFieldDateRange = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...props
}: FormFieldDateRangeProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const errors = useMemo(() => {
    return isDateRangeFieldError(error)
      ? [error?.start, error?.end].filter(
          (item): item is FieldError => item !== undefined
        )
      : [];
  }, [error]);

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  return (
    <FormFieldWrapper error={errors} {...wrapProps}>
      <InputDateRange
        data-testid={name}
        id={name}
        isRequired={wrapProps.isRequired}
        isInvalid={error !== undefined}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
