import { clsx } from 'clsx';

import type { TableCellProps } from './type';

export const TableCell = ({
  size = 'md',
  enableEllipsis = false,
  className,
  children,
}: TableCellProps) => {
  return (
    <div
      className={clsx(
        size === 'md' ? 'tw-text-base tw-py-4' : 'tw-text-xs tw-py-1.5',
        'tw-px-2',
        'tw-flex tw-items-center',
        className
      )}
    >
      {enableEllipsis ? (
        <span className={clsx('tw-line-clamp-1 tw-break-all')}>{children}</span>
      ) : (
        children
      )}
    </div>
  );
};
