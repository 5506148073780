import type { FormFieldWrapperProps } from './type';

import {
  FormFieldHorizontalWrapper,
  FormFieldVerticalWrapper,
} from '@/components/ui';

export const FormFieldWrapper = ({
  wrapType = 'horizontal',
  children,
  ...props
}: FormFieldWrapperProps) => {
  switch (wrapType) {
    case 'horizontal':
      return (
        <FormFieldHorizontalWrapper {...props}>
          {children}
        </FormFieldHorizontalWrapper>
      );
    case 'vertical':
      return (
        <FormFieldVerticalWrapper {...props}>
          {children}
        </FormFieldVerticalWrapper>
      );
  }
};
