import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
} from '@chakra-ui/react';
import { clsx } from 'clsx';
import { useEffect, useMemo, useState } from 'react';

import type { SortSelectorProps } from './type';

import { Button } from '@/components/ui';

import { SORT_DIRRECTION } from './const';

export const SortSelector = ({
  triggerButtonRef,
  currentCondition,
  options,
  maxHeight = 'calc(100vh - 112px)',
  onChange,
}: SortSelectorProps) => {
  const [sortKey, setSortKey] = useState('');

  const { isOpen, onClose, onToggle } = useDisclosure();

  // 現在適用されているソート条件テキスト
  const currentConditionText = useMemo(() => {
    const label = options
      .flatMap(({ options }) => options)
      .find(({ key }) => key === currentCondition.key)?.label;
    return `${label} ${SORT_DIRRECTION[currentCondition.dirrection]}`;
  }, [currentCondition.dirrection, currentCondition.key, options]);

  // 現在適用しようとしているソート対象
  const selectedSortKeyLabel = useMemo(() => {
    return options
      .flatMap(({ options }) => options)
      .find(({ key }) => key === sortKey)?.label;
  }, [options, sortKey]);

  const handleClickSortDirrection = (dirrection: 'asc' | 'desc') => {
    onChange({ key: sortKey, dirrection });
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setSortKey('');
    }
  }, [isOpen]);

  return (
    // NOTE: テーブルのstickyに負けるので大きい数字を指定
    <div className="tw-relative tw-z-banner">
      <Popover
        isOpen={isOpen}
        returnFocusOnClose={false}
        placement="bottom-start"
        onClose={onClose}
      >
        <PopoverTrigger>
          <button
            ref={triggerButtonRef}
            className={clsx(
              'tw-h-6 tw-px-2 tw-flex tw-items-center tw-gap-1 tw-bg-teal-500 tw-text-white tw-rounded-full',
              'hover:tw-bg-teal-400 tw-transition tw-duration-200',
              'focus:tw-bg-teal-600 active:tw-bg-teal-600'
            )}
            onClick={onToggle}
          >
            <span className={clsx('tw-text-sm')}>{currentConditionText}</span>
            <ChevronDownIcon fontSize="sm" />
          </button>
        </PopoverTrigger>
        <PopoverContent
          width="fit-content"
          className={clsx('tw-overflow-y-auto')}
          maxHeight={maxHeight}
        >
          <PopoverBody px={0}>
            <div>
              {!sortKey ? (
                <ul>
                  {options
                    .filter(({ options }) => options.length > 0)
                    .map(({ sectionName, options }) => (
                      <li key={sectionName}>
                        <div className={clsx('tw-px-3 tw-text-gray-600')}>
                          {sectionName}
                        </div>
                        <ul>
                          {options.map(({ key, label }) => (
                            <li key={key}>
                              <button
                                className={clsx(
                                  'tw-block tw-w-full tw-py-1.5 tw-px-6 tw-text-left',
                                  'hover:tw-bg-gray-100 tw-transition tw-duration-200'
                                )}
                                onClick={() => setSortKey(key)}
                              >
                                {label}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              ) : (
                <div className={clsx('tw-px-3')}>
                  <span className={clsx('tw-text-gray-600')}>
                    {selectedSortKeyLabel}
                  </span>
                  <div
                    className={clsx('tw-mt-3 tw-flex tw-items-center tw-gap-2')}
                  >
                    <Button
                      theme="outline"
                      isBlock={false}
                      onClick={() => handleClickSortDirrection('asc')}
                    >
                      {SORT_DIRRECTION['asc']}
                    </Button>
                    <Button
                      theme="outline"
                      isBlock={false}
                      onClick={() => handleClickSortDirrection('desc')}
                    >
                      {SORT_DIRRECTION['desc']}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};
