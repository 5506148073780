import { forwardRef } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { InputSquareRadioGroupProps } from './type';

import { parseToNumber } from '@/utils/string';

export const InputSquareRadioGroup = forwardRef<
  InputSquareRadioGroupProps,
  'div'
>(
  (
    {
      value,
      options,
      isNumericValue = false,
      isDisabled,
      onChange,
    }: InputSquareRadioGroupProps,
    ref
  ) => {
    const handleChange = (value: string) => {
      if (onChange === undefined) return;

      if (value === 'true') {
        onChange(true);
      } else if (value === 'false') {
        onChange(false);
      } else if (isNumericValue) {
        onChange(parseToNumber(value));
      } else {
        onChange(value);
      }
    };

    return (
      <div ref={ref} className={clsx('tw-flex tw-items-center tw-gap-x-2')}>
        {options.map((option, index) => (
          <label key={index}>
            <input
              type="radio"
              aria-hidden
              value={String(option.value)}
              disabled={isDisabled || option.disabled}
              className={clsx('tw-absolute tw-w-[1px] tw-h-[1px] tw-opacity-0')}
            />
            <button
              className={clsx(
                'tw-py-2 tw-px-4 tw-font-bold tw-rounded-md tw-cursor-pointer',
                'tw-border tw-border-gray-400',
                'hover:tw-bg-gray-100 tw-transition tw-duration-200',
                'active:tw-bg-gray-200 active:tw-border-none',
                'disabled:tw-text-gray-500 disabled:tw-cursor-not-allowed',
                option.value === value &&
                  'tw-text-primary-500 tw-border-primary-500'
              )}
              disabled={isDisabled || option.disabled}
              onClick={() => handleChange(String(option.value))}
            >
              {option.label}
            </button>
          </label>
        ))}
      </div>
    );
  }
);
