import { DragIndicator } from '@mui/icons-material';
import { usePrevious } from '@react-pdf-viewer/core';
import { clsx } from 'clsx';
import { memo, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import type { SortableListProps } from './type';
import type { OnDragEndResponder } from 'react-beautiful-dnd';

import { isSequentialSortOrders } from './util';

export const SortableList = memo(
  ({ keys: initialKeys, component, className, onSort }: SortableListProps) => {
    const [keys, setKeys] = useState(initialKeys);
    const prevKeys = usePrevious(keys);

    const callOnSort = useCallback(
      (
        keys: SortableListProps['keys'],
        prevKeys: SortableListProps['keys']
      ) => {
        keys.forEach(({ key, sortOrder }, index) => {
          const prevSortOrder = prevKeys.find(
            ({ key: prevKey }) => prevKey === key
          )?.sortOrder;
          if (sortOrder !== prevSortOrder) {
            onSort?.({ key, sortOrder }, index);
          }
        });
      },
      [onSort]
    );

    const adjustSortOrders = useCallback((keys: SortableListProps['keys']) => {
      if (isSequentialSortOrders(keys)) return;
      const sortedKeys = [...keys].sort((a, b) => a.sortOrder - b.sortOrder);
      return keys.map(({ key }) => ({
        key,
        sortOrder:
          sortedKeys.findIndex(({ key: sortedKey }) => sortedKey === key) + 1,
      }));
    }, []);

    useEffect(() => {
      setKeys(initialKeys);
    }, [initialKeys]);

    useEffect(() => {
      if (prevKeys) {
        callOnSort(keys, prevKeys);
      }
    }, [keys, callOnSort, prevKeys]);

    useEffect(() => {
      const newKeys = adjustSortOrders(keys);
      if (newKeys) {
        setKeys(newKeys);
      }
    }, [keys, adjustSortOrders]);

    const onDragEnd = useCallback<OnDragEndResponder>(
      (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const start = Math.min(source.index, destination.index);
        const end = Math.max(source.index, destination.index);
        const newKeys = keys.map(({ key, sortOrder }) => {
          if (sortOrder < start + 1 || end + 1 < sortOrder) {
            return {
              key,
              sortOrder,
            };
          }
          if (sortOrder === source.index + 1) {
            return {
              key,
              sortOrder: destination.index + 1,
            };
          } else {
            return {
              key,
              sortOrder:
                sortOrder + (source.index < destination.index ? -1 : 1),
            };
          }
        });
        setKeys(newKeys);
      },
      [keys]
    );

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <ul
              className={className}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {[...keys]
                .map(({ key, sortOrder }, index) => ({ key, sortOrder, index })) //並び替え前の index を保持
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(({ key, sortOrder, index }) => (
                  <li key={key}>
                    <Draggable
                      index={sortOrder - 1}
                      draggableId={key.toString()}
                    >
                      {(provided) => (
                        <div
                          className={clsx(
                            'tw-w-full',
                            'tw-flex tw-items-center'
                          )}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {component(
                            {
                              key,
                              sortOrder,
                              DragIndicator: () => (
                                <div
                                  className={clsx('tw-flex tw-items-center')}
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicator
                                    className={clsx('tw-text-gray-500')}
                                  />
                                </div>
                              ),
                            },
                            index
                          )}
                        </div>
                      )}
                    </Draggable>
                  </li>
                ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

SortableList.displayName = 'SortableList';
