import {} from 'react';
import { clsx } from 'clsx';

import type { TableRowSorterProps } from './type';

export const TableRowSorter = ({ sortDirection }: TableRowSorterProps) => {
  return (
    <span
      className={clsx(
        'tw-w-4 tw-flex tw-flex-col tw-items-center tw-space-y-1'
      )}
    >
      <svg
        width="12"
        height="6"
        viewBox="0 0 12 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 0L11.1962 6H0.803848L6 0Z"
          className={clsx(
            !sortDirection
              ? 'tw-fill-gray-400'
              : sortDirection === 'asc'
                ? 'tw-fill-black'
                : 'tw-fill-transparent'
          )}
        />
      </svg>
      <svg
        width="12"
        height="6"
        viewBox="0 0 12 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 6L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 6Z"
          className={clsx(
            !sortDirection
              ? 'tw-fill-gray-400'
              : sortDirection === 'desc'
                ? 'tw-fill-black'
                : 'tw-fill-transparent'
          )}
        />
      </svg>
    </span>
  );
};
