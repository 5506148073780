import { Select, forwardRef } from '@chakra-ui/react';

import type { InputSelectSingleProps } from './type';

import { commonStyle } from '@/components/ui/input/const';

export const InputSelectSingle = forwardRef<InputSelectSingleProps, 'select'>(
  ({ options, placeholder, size = 'md', ...inputProps }, ref) => {
    return (
      <Select
        ref={ref}
        placeholder={
          placeholder === undefined ? '選択する' : placeholder ?? undefined
        }
        {...inputProps}
        {...commonStyle}
        size={size}
        rounded={size === 'sm' ? 'md' : undefined}
      >
        {options.map(({ label, value, disabled, selected }, index) => (
          <option
            key={index}
            value={value}
            disabled={disabled}
            selected={selected}
          >
            {label}
          </option>
        ))}
      </Select>
    );
  }
);

InputSelectSingle.displayName = 'InputSelectSingle';
