import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { HeadlessButtonProps } from './type';

export const HeadlessButton = forwardRef<
  HTMLButtonElement,
  HeadlessButtonProps
>(
  (
    { type = 'button', disabled = false, className, children, ...props },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        disabled={disabled}
        className={clsx(className, 'disabled:tw-cursor-not-allowed')}
        {...props}
      >
        {children}
      </button>
    );
  }
);

HeadlessButton.displayName = 'HeadlessButton';
