import type { UrlObject } from 'url';

import { APP_META } from '@/constants/app';

export const stringifyUrl = (url: UrlObject) => {
  const { pathname } = url;

  if (url.query == null) return pathname;
  const queryString = Object.entries(url.query)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  if (queryString.length === 0) {
    return pathname;
  } else {
    return `${pathname}?${queryString}`;
  }
};

/** 外部サイトかどうか */
export const isExternal = (url: string) => {
  return new URL(url).origin !== APP_META.DOMAIN;
};

/**
 * テキスト内のURLをリンクに変換する
 */
export const urlToLink = (text: string) => {
  return text.replaceAll(
    /(https?:\/\/\S+)/g,
    '<a href="$1" class="tw-text-link tw-text-blue-500 hover:tw-opacity-70 tw-transition tw-duration-200 tw-break-all" target="_blank" rel="noreferrer nofollow">$1</a>'
  );
};
