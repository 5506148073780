import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { MoreHoriz } from '@mui/icons-material';

import type { IconButtonMenuProps } from './type';

import { IconButton, Link } from '@/components/ui';

export const IconButtonMenu = ({
  menuItems,
  isDisabled = false,
  isLoading = false,
  ...iconButtonProps
}: IconButtonMenuProps) => {
  return (
    <Menu placement="right-end">
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            icon={<MoreHoriz fontSize="inherit" />}
            {...iconButtonProps}
            isDisabled={isDisabled}
            isLoading={isLoading}
          />
          {isOpen && (
            <MenuList minWidth="auto" zIndex="popover">
              {menuItems.map((menuItem, index) =>
                menuItem.type === 'button' ? (
                  <MenuItem
                    key={`${menuItem.type}_${index}`}
                    onClick={menuItem.onClick}
                    isDisabled={menuItem.isDisabled}
                  >
                    {menuItem.label}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={`${menuItem.type}_${index}`}
                    isDisabled={menuItem.isDisabled}
                  >
                    {menuItem.isDisabled ? (
                      menuItem.label
                    ) : (
                      <Link href={menuItem.href}>{menuItem.label}</Link>
                    )}
                  </MenuItem>
                )
              )}
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};
