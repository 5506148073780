import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';
import { clsx } from 'clsx';
import { useMemo } from 'react';

import type { FormFieldVerticalWrapperProps } from './type';

export const FormFieldVerticalWrapper = ({
  id,
  label,
  helperText,
  error,
  showLabel = true,
  showError = true,
  isRequired = false,
  isBoldLabel = false,
  children,
}: FormFieldVerticalWrapperProps) => {
  const displayError = useMemo(
    () => (Array.isArray(error) ? error[0] : error),
    [error]
  );

  return (
    <FormControl isRequired={isRequired} isInvalid={error !== undefined}>
      <div className={clsx('tw-flex tw-flex-col tw-items-start tw-space-y-2')}>
        {showLabel && (
          <FormLabel
            htmlFor={id}
            m={0}
            fontWeight={isBoldLabel ? 'bold' : 'normal'}
          >
            {label}
          </FormLabel>
        )}
        <div className={clsx('tw-w-full')}>
          {children}
          {showError && (
            <FormErrorMessage>{displayError?.message}</FormErrorMessage>
          )}
          {helperText !== undefined && (
            <FormHelperText as="div" color="gray.600">
              {helperText}
            </FormHelperText>
          )}
        </div>
      </div>
    </FormControl>
  );
};
