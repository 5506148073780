import { TabPanels as ChakraTabPanels, TabPanel } from '@chakra-ui/react';

import type { TabPanelsProps } from './type';

export const TabPanels = ({ panels }: TabPanelsProps) => {
  return (
    <ChakraTabPanels>
      {panels.map(({ panel }, index) => (
        <TabPanel key={index} padding="0">
          {panel}
        </TabPanel>
      ))}
    </ChakraTabPanels>
  );
};
