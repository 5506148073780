import { useController } from 'react-hook-form';

import type { FormFieldDateProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputDate } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldDate = <T extends FieldValues, U extends FieldPath<T>>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...props
}: FormFieldDateProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);
  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputDate
        data-testid={name}
        id={name}
        isRequired={wrapProps.isRequired}
        isInvalid={error !== undefined}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
