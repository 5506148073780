import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useDisclosure,
  CloseButton,
} from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { AlertProps } from './type';

import { REMARK_COLORS } from './const';

export const Alert = ({
  status,
  title = '',
  description = '',
  remark = '',
  hasCloseButton = false,
  defaultIsOpen = true,
  dirrection = 'row',
}: AlertProps) => {
  const { isOpen, onClose } = useDisclosure({ isOpen: defaultIsOpen });

  return (
    <>
      {isOpen && (
        <ChakraAlert
          status={status}
          variant="subtle"
          alignItems="center"
          rounded="md"
        >
          <div className={'tw-w-full tw-space-y-3'}>
            <div
              className={clsx(
                'tw-flex',
                dirrection === 'row' ? 'tw-flex-row' : 'tw-flex-col'
              )}
            >
              <div className={clsx('tw-flex tw-items-center')}>
                <AlertIcon />
                {title && (
                  <AlertTitle paddingY={1} flexShrink={0}>
                    {title}
                  </AlertTitle>
                )}
                <AlertDescription width="full" paddingY={1}>
                  {description}
                </AlertDescription>
              </div>
            </div>
            {remark && (
              <p
                className={clsx(
                  'tw-w-full',
                  'tw-px-4 tw-py-2',
                  'tw-rounded',
                  'tw-whitespace-break-spaces',
                  REMARK_COLORS[status ?? 'info']
                )}
              >
                {remark}
              </p>
            )}
          </div>
          {hasCloseButton && (
            <CloseButton alignSelf="flex-start" onClick={onClose} />
          )}
        </ChakraAlert>
      )}
    </>
  );
};
