import { useController } from 'react-hook-form';

import type { FormFieldFileProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputFile } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldFile = <T extends FieldValues, U extends FieldPath<T>>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...props
}: FormFieldFileProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <InputFile
        data-testid={name}
        isInvalid={error !== undefined}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
