export const isAccept = ({
  files,
  acceptList,
}: {
  files: FileList;
  acceptList?: string[];
}) => {
  if (!acceptList) {
    return true;
  }

  // 大文字拡張子を生成・追加
  const acceptExtentions = [
    ...acceptList,
    ...acceptList.map((ext) => ext.toUpperCase()),
  ];

  for (let i = 0; i < files.length; i++) {
    const fileExtension = files[i].name.split('.').slice(-1)[0];
    if (!acceptExtentions.includes(`.${fileExtension}`)) {
      return false;
    }
  }

  return true;
};

export const checkIsUnderFileSizeLimit = (
  file: File,
  maxFileSizeMb: number
) => {
  return file.size <= maxFileSizeMb * 2 ** 20;
};
