import { themes as buttonThemes } from '@/components/ui/button/Button/const';

export const themes = {
  valid: buttonThemes.outline,
  invalid: {
    ...buttonThemes.outline,
    borderColor: 'red.500',
    borderWidth: '2px',
  },
};
