import { Link } from '@mui/icons-material';
import { forwardRef } from 'react';

import type { InputUrlProps } from './type';

import { InputText } from '@/components/ui';

export const InputUrl = forwardRef<HTMLInputElement, InputUrlProps>(
  (props, ref) => {
    return <InputText ref={ref} {...props} icon={<Link fontSize="small" />} />;
  }
);

InputUrl.displayName = 'InputUrl';
