import { RadioGroup, Radio, forwardRef } from '@chakra-ui/react';
import { clsx } from 'clsx';

import type { InputRadioGroupProps } from './type';

import { commonStyle } from '@/components/ui/input/const';
import { parseToNumber } from '@/utils/string';

import { parseValue } from './util';

export const InputRadioGroup = forwardRef<InputRadioGroupProps, 'div'>(
  (
    {
      value,
      options,
      direction = 'row',
      size = 'md',
      isNumericValue = false,
      onChange,
      ...inputProps
    }: InputRadioGroupProps,
    ref
  ) => {
    const handleChange = (value: string) => {
      if (onChange === undefined) return;

      if (value === 'true') {
        onChange(true);
      } else if (value === 'false') {
        onChange(false);
      } else if (isNumericValue) {
        onChange(parseToNumber(value));
      } else {
        onChange(value);
      }
    };

    return (
      <RadioGroup
        value={parseValue(value)}
        onChange={handleChange}
        size={size}
        {...inputProps}
      >
        <div
          className={clsx(
            'tw-flex',
            direction === 'row'
              ? 'tw-flex-row tw-space-x-8'
              : 'tw-flex-col tw-space-y-2'
          )}
        >
          {options.map(({ label, value, disabled }, index) => (
            <span
              key={index}
              className={clsx('tw-inline-flex tw-items-center tw-space-x-2')}
            >
              <Radio
                ref={ref}
                value={parseValue(value)}
                isDisabled={disabled || inputProps.isDisabled}
                {...commonStyle}
              />
              <span
                className={clsx(
                  disabled || inputProps.isDisabled
                    ? 'tw-text-gray-400 tw-cursor-not-allowed'
                    : 'tw-cursor-pointer'
                )}
                onClick={
                  disabled || inputProps.isDisabled
                    ? undefined
                    : () => handleChange(String(value))
                }
              >
                {label}
              </span>
            </span>
          ))}
        </div>
      </RadioGroup>
    );
  }
);
