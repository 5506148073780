import {
  InputGroup,
  Input,
  InputLeftElement,
  forwardRef,
} from '@chakra-ui/react';

import type { InputTextProps } from './type';
import type { KeyboardEventHandler } from 'react';

import { commonStyle } from '@/components/ui/input/const';

export const InputText = forwardRef<InputTextProps, 'input'>(
  (
    {
      placeholder = '入力してください',
      icon,
      inputRef,
      onKeyDownEnter,
      ...inputProps
    },
    ref
  ) => {
    const handleKeyDownEnter: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key == 'Enter' && onKeyDownEnter !== undefined) {
        e.preventDefault();
        onKeyDownEnter();
      }
    };

    return (
      <InputGroup size={inputProps.size}>
        {icon !== undefined && (
          <InputLeftElement pointerEvents="none" color="gray.500" pl="2">
            {icon}
          </InputLeftElement>
        )}
        <Input
          ref={inputRef || ref}
          placeholder={placeholder}
          {...inputProps}
          {...commonStyle}
          rounded="6px"
          onKeyDown={handleKeyDownEnter}
        />
      </InputGroup>
    );
  }
);

InputText.displayName = 'InputText';
