import { clsx } from 'clsx';
import { useState, useEffect, useCallback, forwardRef } from 'react';

import type { InputDateRangeProps } from './type';

import { InputDate } from '@/components/ui';
import { getMinDate, getMaxDate } from '@/utils/date';

export const InputDateRange = forwardRef<HTMLInputElement, InputDateRangeProps>(
  (
    {
      id,
      name,
      value,
      minDate,
      maxDate,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      ...commonInputDateProps
    },
    ref
  ) => {
    const handleChange = useCallback(
      (key: keyof typeof value, input: string | Date | null) => {
        onChange({
          ...value,
          [key]: input,
        });
        if (key === 'start') {
          setIsStartActive(false);
        } else {
          setIsEndActive(false);
        }
      },
      [value, onChange]
    );

    const [isStartActive, setIsStartActive] = useState<boolean>(false);
    const [isEndActive, setIsEndActive] = useState<boolean>(false);

    useEffect(() => {
      if (isStartActive || isEndActive) {
        onFocus && onFocus();
      } else if (!isStartActive && !isEndActive) {
        onBlur && onBlur();
      }
    }, [isStartActive, isEndActive, onFocus, onBlur]);

    return (
      <div className={clsx('tw-flex tw-items-center')}>
        <InputDate
          ref={ref}
          id={`${id}-start`}
          name={`${name}-start`}
          value={value?.start}
          placeholder={placeholder?.start ?? '開始日'}
          minDate={minDate}
          maxDate={getMinDate([maxDate, value?.end])}
          onChange={(input) => handleChange('start', input)}
          onFocus={() => {
            setIsStartActive(true);
          }}
          onBlur={() => {
            setIsStartActive(false);
          }}
          {...commonInputDateProps}
        />

        <span className={clsx('tw-mx-4', 'tw-text-xs')}>～</span>

        <InputDate
          id={`${id}-end`}
          name={`${name}-end`}
          value={value?.end}
          placeholder={placeholder?.end ?? '終了日'}
          minDate={getMaxDate([minDate, value?.start])}
          maxDate={maxDate}
          onChange={(input) => handleChange('end', input)}
          onFocus={() => {
            setIsEndActive(true);
          }}
          onBlur={() => {
            setIsEndActive(false);
          }}
          {...commonInputDateProps}
        />
      </div>
    );
  }
);

InputDateRange.displayName = 'InputDateRange';
