import { Tag as ChakraTag, TagLabel } from '@chakra-ui/react';

import type { TagProps } from './type';

export const Tag = ({
  children,
  variant,
  colorScheme,
  size = 'md',
  backgroundColor,
  color,
  style,
}: TagProps) => {
  return (
    <ChakraTag
      variant={variant}
      colorScheme={colorScheme}
      backgroundColor={backgroundColor}
      size={size}
      minWidth="max-content"
      borderRadius="md"
      whiteSpace="nowrap"
      color={color}
      style={style}
    >
      <TagLabel>{children}</TagLabel>
    </ChakraTag>
  );
};
