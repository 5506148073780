import type { ImageProps } from './type';
import type { ImageLoader } from 'next/image';

import { BREAKPOINTS } from '@/constants/page';

import { IMAGE_DELIVERY_DOMAIN, IMAGE_ORIGINAL_DOMAIN } from './const';

export const loader: ImageLoader | undefined = IMAGE_DELIVERY_DOMAIN
  ? ({ src, width, quality }) => {
      if (!src.startsWith(`https://${IMAGE_ORIGINAL_DOMAIN}`)) {
        return src;
      }
      const pathname = src.replace(`https://${IMAGE_ORIGINAL_DOMAIN}`, '');
      return quality
        ? `https://${IMAGE_DELIVERY_DOMAIN}${pathname}?format=webp&quality=${quality}&width=${width}`
        : `https://${IMAGE_DELIVERY_DOMAIN}${pathname}?format=webp&width=${width}`;
    }
  : undefined;

export const generateSizes = (size: ImageProps['size']) => {
  if (size === undefined) return undefined;
  let sizes: string[] = [];
  if (size.sp !== undefined) {
    sizes = [...sizes, `(max-width: ${BREAKPOINTS.md}px) ${size.sp}`];
  }
  if (size.tablet !== undefined) {
    sizes = [...sizes, `(max-width: ${BREAKPOINTS.lg}px) ${size.tablet}`];
  }
  if (size.pc !== undefined) {
    sizes = [...sizes, size.pc];
  }

  return sizes.length > 0 ? sizes.join(', ') : undefined;
};
