import { clsx } from 'clsx';
import { useCallback, useState } from 'react';
import { useController } from 'react-hook-form';

import type { FormFieldNumberProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputNumber } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';
import { parseToNumber } from '@/utils/string';

export const FormFieldNumber = <T extends FieldValues, U extends FieldPath<T>>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  unit,
  ...props
}: FormFieldNumberProps<T, U>) => {
  const {
    field: { value, ...restField },
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });
  const [localValue, setLocalValue] = useState<string>(
    `${value}` != null ? value : ''
  );

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  /**
   * MEMO: React-hook-form側ではnumberとして扱いたいので、onChangeでは変換をかける
   * InputNumberでは未入力を空文字で扱う必要があるため別途変換をかけている
   */
  const handleChange = useCallback(
    (input: string) => {
      const v = parseToNumber(input);
      if (v !== undefined) {
        restField.onChange(v);
        setLocalValue(`${v}`);
      } else {
        restField.onChange(undefined);
        setLocalValue('');
      }
    },
    [restField]
  );

  return (
    <FormFieldWrapper error={error} {...wrapProps}>
      <div className={clsx('tw-w-full tw-flex tw-items-center tw-space-x-4')}>
        <InputNumber
          data-testid={name}
          id={name}
          value={localValue}
          isRequired={wrapProps.isRequired}
          isInvalid={error !== undefined}
          {...inputProps}
          {...restField}
          onChange={handleChange}
        />
        {unit && <p className={clsx('tw-whitespace-nowrap')}>{unit}</p>}
      </div>
    </FormFieldWrapper>
  );
};
