import { clsx } from 'clsx';

import type { ActionModalProps } from './type';

import { BaseModal, Button } from '@/components/ui';

export const ActionModal = ({
  cancelButtonText = 'キャンセル',
  confirmButtonText = '確定する',
  onCancel,
  onConfirm,
  children,
  isDisabledConfirmButton = false,
  isLoadingConfirmButton = false,
  isAlert = false,
  ...modalProps
}: ActionModalProps) => {
  const footer = (
    <div className={clsx('tw-flex tw-items-center', 'tw-space-x-3')}>
      {onCancel !== undefined && (
        <Button
          theme="gray"
          isBlock={false}
          onClick={onCancel}
          isDisabled={isLoadingConfirmButton}
        >
          {cancelButtonText}
        </Button>
      )}
      {onConfirm !== undefined && (
        <Button
          theme={isAlert ? 'alert' : 'solid'}
          isBlock={false}
          onClick={onConfirm}
          isDisabled={isDisabledConfirmButton}
          isLoading={isLoadingConfirmButton}
        >
          {confirmButtonText}
        </Button>
      )}
    </div>
  );

  return (
    <BaseModal footer={footer} {...modalProps}>
      {children}
    </BaseModal>
  );
};
