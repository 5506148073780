import { clsx } from 'clsx';

import type { PaperProps } from './type';

export const Paper = ({
  children,
  isHeightFull = false,
  className,
}: PaperProps) => {
  return (
    <div
      className={clsx(
        'tw-p-4 md:tw-p-8 tw-bg-white md:tw-rounded-[8px] tw-shadow-base',
        isHeightFull && 'tw-h-full',
        className
      )}
    >
      {children}
    </div>
  );
};
