import { clsx } from 'clsx';
import NextImage from 'next/image';

import type { ImageProps } from './type';
import type { ReactEventHandler } from 'react';

import { NO_IMAGE_SRC, OBJECT_FIT_CLASS_NAMES } from './const';
import { loader, generateSizes } from './util';

export const Image = ({
  src,
  alt = '',
  objectFit = 'scale-down',
  priority = false,
  size,
  unoptimized = false,
  onLoadingComplete,
  className,
  style,
}: ImageProps) => {
  const onImageError: ReactEventHandler<HTMLImageElement> = (event) => {
    const imageElement = event.target as HTMLImageElement;
    imageElement.onerror = null;
    imageElement.src = NO_IMAGE_SRC;
  };

  return (
    <NextImage
      src={src || NO_IMAGE_SRC}
      alt={alt}
      fill
      priority={priority}
      className={clsx(
        '!tw-static',
        OBJECT_FIT_CLASS_NAMES(objectFit),
        className
      )}
      style={style}
      sizes={generateSizes(size)}
      unoptimized={loader ? unoptimized : true}
      onLoadingComplete={onLoadingComplete}
      onError={onImageError}
      loader={loader}
    />
  );
};
