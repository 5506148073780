import { useController } from 'react-hook-form';

import type { FormFieldTextareaProps } from './type';
import type { FieldPath, FieldValues } from 'react-hook-form';

import { FormFieldWrapper, InputTextarea } from '@/components/ui';
import { extractWrapperProps } from '@/components/ui/form/FormFieldWrapper/util';

export const FormFieldTextarea = <
  T extends FieldValues,
  U extends FieldPath<T>,
>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  isReadOnly = false,
  showMaxLength,
  ...props
}: FormFieldTextareaProps<T, U>) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control, shouldUnregister, defaultValue });

  const { wrapProps, rest: inputProps } = extractWrapperProps(props);

  const maxLengthText =
    showMaxLength && inputProps.maxLength !== undefined
      ? `${inputProps.maxLength.toLocaleString()}文字以内`
      : undefined;

  return (
    <FormFieldWrapper
      error={error}
      {...wrapProps}
      helperText={
        <>
          <p>{wrapProps.helperText}</p>
          <p>{maxLengthText}</p>
        </>
      }
    >
      <InputTextarea
        data-testid={name}
        id={name}
        isRequired={wrapProps.isRequired}
        isInvalid={error !== undefined}
        isReadOnly={isReadOnly}
        {...inputProps}
        {...field}
      />
    </FormFieldWrapper>
  );
};
